<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import FooterNav from "@/components/FooterNav.vue";
export default {
  components: { HeaderNav, FooterNav },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
}
p {
  text-wrap: wrap;
}
</style>
