<template>
  <div class="pc">
    <HeaderNav></HeaderNav>
    <router-view />
    <FooterNav></FooterNav>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav.vue";
import FooterNav from "@/components/FooterNav.vue";
export default {
  components: { HeaderNav, FooterNav },
};
</script>

<style>
.pc {
  min-width: 1200px;
  overflow: auto;
}
</style>
