<template>
  <div class="header">
    <div class="box">
      <img src="@/assets/header_logo2.png" />
      <div class="nav-list">
        <div :class="active === t.path && 'active'" @click="handle(t.path)" v-for="(t, i) in navList" :key="i">
          {{ t.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "/",
      navList: [
        { name: "首页", path: "/" },
        { name: "每橙业务", path: "/business" },
        { name: "每橙生态", path: "/ecology" },
        // { name: "关于我们", path: "/about" },
        { name: "联系我们", path: "/contact" },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (to, from) {
        this.active = to.path;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handle(path) {
      this.active = path;
      this.$router.push(path);
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-shadow: 0 0 10px 1px rgba(255, 80, 27, 0.2);
  img {
    width: 280px;
    height: 60px;
  }
  .box {
    width: 1200px;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-list {
    display: flex;
    justify-content: space-between;
    width: 500px;
    font-size: 18px;
    div {
      height: 50px;
      line-height: 50px;
      font-weight: bold;
      cursor: pointer;
    }
    .active {
      color: #ff501b;
      border-bottom: 2px solid #ff501b;
    }
  }
}
</style>
