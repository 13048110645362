import Vue from "vue";
import VueRouter from "vue-router";
import Mobile from "@/views/mobile/index"
import Pc from "@/views/index"
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    // component: () => import("../views/home.vue"),
    component: Pc,
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/home.vue"),
      },
      {
        path: "business",
        name: "business",
        component: () => import("../views/business.vue"),
      },
      {
        path: "ecology",
        name: "ecology",
        component: () => import("../views/ecology.vue"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("../views/about.vue"),
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("../views/contact.vue"),
      },

    ]
  },
  {

    path: '/modile',
    component: Mobile,
    children: [
      {
        path: "updataVer",
        name: "更新微信版本",
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/mobile/updateVersion.vue"),
      },

    ]

  },
];

const router = new VueRouter({
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};


router.beforeEach((to, from, next) => {
  // Check if the route is "/" and redirect to "/home"
  if (to.path === '/') {
    next('/home');
  } else {
    next();
  }
});

export default router;
